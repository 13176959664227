import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";

export const Newsletter = ({ status, message, onValidated }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bsmj7ie",
        "template_v738zwy",
        form.current,

        "kpRCB8rB_H6EFP_sS"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          console.log("message sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>
              Subscribe to our Newsletter<br></br> & Never miss latest updates
            </h3>
          </Col>
          <div>
            <form ref={form} onSubmit={sendEmail} style={{ margin: "2rem" }}>
              <input
                name="user_email"
                placeholder="Email Address"
                type="email"
              />
              <button className="btn-newsletter" value="Send" type="submit">
                <span>Submit</span>
              </button>
            </form>
          </div>
        </Row>
      </div>
    </Col>
  );
};
