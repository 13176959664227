import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { GalleryCard } from "./GalleryCard";
import tow1 from "../assets/img/tow1.jpg";
import tow2 from "../assets/img/tow2.jpg";
import tow3 from "../assets/img/tow3.jpg";
import tow4 from "../assets/img/tow4.jpg";
import tow5 from "../assets/img/tow5.jpg";
import tow6 from "../assets/img/tow6.jpg";
import tow7 from "../assets/img/tow7.jpg";
import tow8 from "../assets/img/tow8.jpg";
import tow9 from "../assets/img/tow9.jpg";
import tow10 from "../assets/img/tow10.jpg";
import tow11 from "../assets/img/tow11.jpg";
import tow12 from "../assets/img/tow12.jpg";
import tow13 from "../assets/img/tow13.jpg";
import tow14 from "../assets/img/tow14.jpg";
import tow15 from "../assets/img/tow15.jpg";
import tow16 from "../assets/img/tow16.jpg";
import "animate.css";
import TrackVisibility from "react-on-screen";


export const Gallery = () => {
  const galleriesTab1 = [
    {
      title: "Wilmington, CA",
      description: "",
      imgUrl: tow1,
    },
    {
      title: "Long Beach, CA",
      description: "",
      imgUrl: tow2,
    },
    {
      title: "Long Beach, CA",
      description: "",
      imgUrl: tow3,
    },
    {
      title: "Los Angeles, CA",
      description: "",
      imgUrl: tow4,
    },
    {
      title: "Wilmington, CA",
      description: "",
      imgUrl: tow5,
    },
    {
      title: "Wilmington, CA",
      description: "",
      imgUrl: tow6,
    },
    {
      title: "Las Vegas, NV",
      description: "",
      imgUrl: tow7,
    },
    {
      title: "Phoenix, AZ",
      description: "",
      imgUrl: tow8,
    },
  ];

  const galleriesTab2 = [
    {
      title: "Santa Fe, NM",
      description: "",
      imgUrl: tow9,
    },
    {
      title: "Alondra, CA",
      description: "",
      imgUrl: tow10,
    },
    {
      title: "Orlando, FL",
      description: "",
      imgUrl: tow11,
    },
    {
      title: "Long Beach, CA",
      description: "",
      imgUrl: tow12,
    },
    {
      title: "Rialto, CA",
      description: "",
      imgUrl: tow13,
    },
    {
      title: "Salt Lake City, UT",
      description: "",
      imgUrl: tow14,
    },
    {
      title: "Long Beach, CA",
      description: "",
      imgUrl: tow15,
    },
    {
      title: "Wilmington, CA",
      description: "",
      imgUrl: tow16,
    },
  ];

  return (
    <section className="gallery" id="galleries">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Gallery</h2>
                  <p>
                    Do you know a towing service with heavy-duty tow trucks
                    available that you can depend on when your business needs
                    assistance?
                    <br></br>
                    Whether you own a construction or a transport company, any
                    time you need to move a heavy load from point A to point B,
                    you run the risk of a breakdown, and having a team of
                    skilled operators with heavy-duty tow trucks on call is an
                    invaluable asset.
                  </p>
                  <Tab.Container id="galleries-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {galleriesTab1.map((gallery, index) => {
                            return <GalleryCard key={index} {...gallery} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {galleriesTab2.map((gallery, index) => {
                            return <GalleryCard key={index} {...gallery} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Emergency towing: We provide emergency towing services
                          to truck drivers who are stranded due to mechanical
                          breakdowns, accidents, or other unforeseen
                          circumstances. <br></br> Roadside assistance: We offer
                          services such as tire changes, battery jumps, fuel
                          delivery, and lockout assistance. <br></br> Recovery
                          services: If a semi-truck gets stuck in mud, sand, or
                          snow, we provide recovery services to get the vehicle
                          back on the road. <br></br> Transport services: We
                          also offer transport services to move semi-trucks from
                          one location to another.<br></br> Salvage services: We
                          offer salvage services to recover and dispose of
                          damaged or wrecked semi-trucks. <br></br> Mobile car
                          washing: We also offer mobile car washing services,
                          where our customers go to our location and we wash and
                          detail their vehicles.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={italy}></img> */}
    </section>
  );
};
