import salvage from "../../src/assets/img/salvage.svg";
import transport from "../../src/assets/img/transport.svg";
import emergency from "../../src/assets/img/emergency.svg";
import roadside from "../../src/assets/img/roadside.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import secondBg from "../assets/img/newPic2.jpg";

export const Services = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="service" id="services">
      {/* <div className="container"> */}
      <div className="row">
        <div className="col-12">
          <div className="service-bx wow zoomIn">
            <h2>Services</h2>
            <p>
              We provide our service all across the United States. <br></br>
              "Transportation that works as hard as you do."
            </p>
            <Carousel
              responsive={responsive}
              infinite={true}
              className="owl-carousel owl-theme service-slider"
            >
              <div className="item">
                <img src={emergency} alt="random logo" />
                <h5>
                  EMERGENCY <br></br> TOWING
                </h5>
              </div>
              <div className="item">
                <img src={roadside} alt="random logo" />
                <h5>
                  ROADSIDE <br></br> ASSISTANCE
                </h5>
              </div>
              <div className="item">
                <img src={transport} alt="random logo" />
                <h5>
                  TRANSPORT <br></br> SERVICE
                </h5>
              </div>
              <div className="item">
                <img src={salvage} alt="random logo" />
                <h5>
                  SALVAGE <br></br> SERVICE
                </h5>
              </div>
            </Carousel>
          </div>
        </div>
        {/* </div> */}
      </div>
      <img className="background-image-left" src={secondBg} alt="random logo" />
    </section>
  );
};
