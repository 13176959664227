import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">NOVOA TOWING</span>

                  <p>
                    WELCOME TO NOVOA TOWING! BASED IN THE CITY OF LONG BEACH,
                    CALIFORNIA, WE PROVIDE CUSTOMERS WITH THE BEST QUALITY
                    SERVICE.
                  </p>
                  <button>
                    Let’s Connect{" "}
                    <ArrowRightCircle size={25} style={{ margin: "10px" }} />{" "}
                    562.822.5220
                  </button>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  {/* <img src={logo} alt="Header Img" /> */}
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
