import { Container, Row, Col } from "react-bootstrap";
import logo from "../../src/assets/img/theOne.png";
import ig from "../../src/assets/img/newIg.svg";
import facebook from "../../src/assets/img/newFacebook.svg";
import { Newsletter } from "./Newsletter";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Newsletter />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/novoatowing/"
              >
                <img src={ig} alt="Icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/joaquin.novoa.779"
              >
                <img src={facebook} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
